import React, { useEffect } from "react";
import { signout, createGuestAccount, signin } from "apiCalls/apiCalls";
import { useHistory } from "react-router";
import { bake_cookie } from "sfcookies";

const LogOut = () => {
  const history = useHistory();

  useEffect(() => {
    signout(() => {
      createGuestAccount().then((data) => {
        if (data.error) {
          console.log("Error", data.error);
        } else {
          let signInCredentials = {
            email: data.email,
            password: "123456",
          };

          signin(signInCredentials).then((data) => {
            if (data.error) {
              console.log("Error", data.error);
            } else {
              // bake_cookie("userToken", data.token);
              // bake_cookie("userInfo", data.user);

              function setCookie(name, value, days) {
                var expires = "";
                if (days) {
                  var date = new Date();
                  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                  expires = "; expires=" + date.toUTCString();
                }
                document.cookie =
                  name + "=" + (value || "") + expires + "; path=/";
              }

              setCookie("userToken", `${JSON.stringify(data.token)}`, 10000);
              setCookie("userInfo", `${JSON.stringify(data.user)}`, 10000);

              history.push("/");
            }
          });
        }
      });
    });
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ width: "100vw", height: "100vh" }}
      >
        <h1 className="text-white">Logging Out...</h1>
      </div>
    </>
  );
};

export default LogOut;
