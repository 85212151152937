import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";

const Chart4 = ({ labels, data }) => {
  const helperFunction = () => {
    let tempLabel = [];

    for (let i = 0; i < 6 && i < labels.length; i++) {
      let element = labels[i];

      if (element.length > 12) {
        element = element.substring(0, 12);
      }

      tempLabel.push(element);
    }

    return tempLabel;
  };

  return (
    <Bar
      data={{
        // labels: ["USA", "GER", "AUS", "UK", "RO", "BR"],
        labels: helperFunction(),
        // labels: labels,
        // labels: tempLabel,
        datasets: [
          {
            label: "Hearts",
            fill: true,
            // backgroundColor: "#e14eca",
            hoverBackgroundColor: "#e14eca",
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            // data: [53, 20, 10, 80, 100, 45],
            data: data,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                suggestedMin: 60,
                suggestedMax: 120,
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
        },
      }}
    />
  );
};

export default Chart4;
