import { LittleHeartsAPI } from "backend";

// SignUp API
export const signup = (user) => {
  return fetch(`${LittleHeartsAPI}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createGuestAccount = () => {
  return fetch(`${LittleHeartsAPI}/user/guestAccount`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// Update guest account to user account API
export const updateGuestAcc = (body) => {
  return fetch(`${LittleHeartsAPI}/user/updateUserInfo`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateGuestAcc2 = (body) => {
  console.log(body);
  return fetch(`${LittleHeartsAPI}/user/updateUserInfo2`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const secondSignUpGuestUpdate = (body) => {
  return fetch(`${LittleHeartsAPI}/user/updateGuestUser`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// SignIn API
export const signin = (user) => {
  return fetch(`${LittleHeartsAPI}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// Method associated with signin method
// Puts the necessary user data in local storage of window object
export const authenticate = (data, next) => {
  // setJwt(data);
  if (typeof window !== "undefined") {
    // localStorage.setItem("jwt", JSON.stringify(data));
  }
  next();
};

// Checks whether user is signed in with the help of jwt kept in local storage of window object
export const isSignedIn = () => {
  if (typeof window == "undefined") {
    return false;
  }

  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

// SignOut API
export const signout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();

    return fetch(`${LittleHeartsAPI}/signout`, {
      method: "GET",
    })
      .then((response) => console.log("Signout Success"))
      .catch((err) => console.log(err));
  }
};

// Get user data
export const getUser = (token, userID) => {
  return fetch(`${LittleHeartsAPI}/user/${userID}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// GetUserDashboard API
export const getUserDashboard = (token) => {
  return fetch(`${LittleHeartsAPI}/user/dashboard`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// GetContentCreatorDashboard API
export const getContentCreatorDashboard = (token) => {
  return fetch(`${LittleHeartsAPI}/user/creator/dashboard`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const getPreviousHoursHearts = (token) => {
  return fetch(`${LittleHeartsAPI}/user/creator/heartsprevhours`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const getPreviousDaysHearts = (token) => {
  return fetch(`${LittleHeartsAPI}/user/creator/heartsprevdays`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const getTopContents = (token) => {
  // return fetch(`http://localhost:8080/littlehearts/user/creator/topcontents`, {
  return fetch(`${LittleHeartsAPI}/user/creator/topcontents`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const getCountrywiseData = (token) => {
  return fetch(`${LittleHeartsAPI}/user/creator/countrydata`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const youtubeContentVerification = (token) => {
  return fetch(`${LittleHeartsAPI}/user/verify/youtube`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const youtubeContentVerificationResponse = (token, body) => {
  return fetch(`${LittleHeartsAPI}/verify/youtube/callback`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const getClaimedContents = (token) => {
  return fetch(`${LittleHeartsAPI}/claimedContents`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const updateMessageToConsumers = (token, body) => {
  return fetch(`${LittleHeartsAPI}/user/updateMsgToConsumer`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const generateTxtRecord = (token, body) => {
  return fetch(`${LittleHeartsAPI}/user/generateTxt`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const websiteVerification = (token, body) => {
  return fetch(`${LittleHeartsAPI}/verify/website`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const sendPasswordRecoveryMail = (body) => {
  return fetch(`${LittleHeartsAPI}/forgotPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (body) => {
  return fetch(`${LittleHeartsAPI}/resetPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const verifyEmail = (body) => {
  return fetch(`${LittleHeartsAPI}/verifyEmail`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const unclaimContent = (token, body) => {
  // return fetch(`http://localhost:8080/littlehearts/unclaim/content`, {
  return fetch(`${LittleHeartsAPI}/unclaim/content`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};
