import React, { useEffect, useState } from "react";
import { resetPassword } from "apiCalls/apiCalls";
import { Link, Redirect, useHistory } from "react-router-dom";
import "./authenticatePage.css";
import "./manual.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [error, setError] = useState(false);
  const [verificationString, setVerificationString] = useState("");

  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("q");

    setVerificationString(myParam);
  }, []);

  const errorMessage = () => {
    return (
      <div
        className="errorMsg"
        style={{
          display: error ? "inline-block" : "none",
          textAlign: "center",
          position: "fixed",
          top: "5%",
          zIndex: 15,
          backgroundColor:
            error === "Password updated successfully!"
              ? "rgb(122 234 105)"
              : "rgb(255, 71, 71)",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <p style={{ color: "white", overflowWrap: "break-word" }}>{error}</p>
      </div>
    );
  };

  const cnfAndResetPassword = () => {
    if (password !== cnfPassword) {
      setError("Password is not matching!");
    } else if (password === cnfPassword) {
      const body = {
        password: cnfPassword,
        verificationString: verificationString,
      };

      resetPassword(body).then((data) => {
        if (data.error) {
          console.log("error", data.error);
          setError(data.error);
        } else {
          console.log(data);
          setPassword("");
          setCnfPassword("");

          setError(data.msg);
          setTimeout(() => {
            history.push("/signIn");
          }, process.env.SETTIMEOUT5);
        }
      });
    }
  };

  const resetPasswordForm = () => {
    return (
      <div>
        {/* <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
              rel="stylesheet"
            /> */}
        <div className="main-content">
          {/* Navbar */}
          <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
            <div className="container px-4">
              <a
                className="navbar-brand"
                style={{ color: "white", position: "static" }}
                // href="https://www.creative-tim.com/product/argon-dashboard"
                target="_blank"
              >
                Little Hearts
              </a>
              {/* <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar-collapse-main"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button> */}
              <div
                className="collapse navbar-collapse"
                id="navbar-collapse-main"
              >
                {/* Collapse header */}
                <div className="navbar-collapse-header d-md-none">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      {/* <a href="../index.html">Argon</a> */}
                    </div>
                    <div className="col-6 collapse-close">
                      <button
                        type="button"
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-main"
                        aria-controls="sidenav-main"
                        aria-expanded="false"
                        aria-label="Toggle sidenav"
                      >
                        <span />
                        <span />
                      </button>
                    </div>
                  </div>
                </div>
                {/* Navbar items */}
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="/signUp"
                      // target="_blank"
                    >
                      <i className="ni ni-circle-08" />
                      {/* <Link to="/signUp"> */}
                      <span className="nav-link-inner--text">Sign Up</span>
                      {/* </Link> */}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link nav-link-icon"
                      href="/signIn"
                      // target="_blank"
                    >
                      <i className="ni ni-key-25" />
                      {/* <Link to="/signIn"> */}
                      <span className="nav-link-inner--text">Sign In</span>
                      {/* </Link> */}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                        <a
                          className="nav-link nav-link-icon"
                          //   href="https://www.creative-tim.com/product/argon-dashboard"
                          target="_blank"
                        >
                          <i className="ni ni-single-02" />
                          <span className="nav-link-inner--text">Profile</span>
                        </a>
                      </li> */}
                </ul>
              </div>
            </div>
          </nav>
          {/* Header */}
          <div className="header bg-gradient-primary py-7 py-lg-6">
            <div className="container">
              <div className="header-body text-center mb-7">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-6">
                    {/* <h1 className="text-white">Welcome to Little Hearts!</h1> */}
                    <p className="text-lead text-white">Reset Password</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                x={0}
                y={0}
                viewBox="0 0 2560 100"
                preserveAspectRatio="none"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <div className="container mt--8 pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-7">
                <div className="card bg-secondary shadow border-0">
                  {/* <div className="card-header bg-transparent pb-5">
                        <div className="text-muted text-center mt-2 mb-3">
                          <small>Sign in with</small>
                        </div>
                        <div className="btn-wrapper text-center">
                          <a href="#" className="btn btn-neutral btn-icon">
                            <span className="btn-inner--icon">
                              <img src="https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/img/icons/common/github.svg" />
                            </span>
                            <span className="btn-inner--text">Github</span>
                          </a>
                          <a href="#" className="btn btn-neutral btn-icon">
                            <span className="btn-inner--icon">
                              <img src="https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/img/icons/common/google.svg" />
                            </span>
                            <span className="btn-inner--text">Google</span>
                          </a>
                        </div> 
                      </div>*/}
                  <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Enter new password</small>
                    </div>
                    <form role="form">
                      <div className="form-group mb-3">
                        <div className="input-group input-group-alternative">
                          {/* <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-email-83" />
                                </span>
                              </div> */}
                          <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(event) => {
                              setPassword(event.target.value);
                              setError(false);
                            }}
                            style={{
                              color: "black",
                              border: "1px solid black",
                            }}
                            autoFocus
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <div className="input-group input-group-alternative">
                          {/* <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ni ni-email-83" />
                                </span>
                              </div> */}
                          <input
                            className="form-control"
                            placeholder="Confirm Password"
                            type="password"
                            value={cnfPassword}
                            onChange={(event) => {
                              setCnfPassword(event.target.value);
                              setError(false);
                            }}
                            style={{
                              color: "black",
                              border: "1px solid black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                        />
                        {/* <label
                              className="custom-control-label"
                              htmlFor=" customCheckLogin"
                            >
                              <span className="text-muted">Remember me</span>
                            </label> */}
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary my-1"
                          onClick={cnfAndResetPassword}
                        >
                          Confirm
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <a href="/signUp" className="text-light">
                      <small>New on Little Hearts?</small>
                    </a>
                  </div>
                  <div className="col-6 text-right">
                    <a href="/signIn" className="text-light">
                      <small>Already have an account?</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="py-4">
          <div className="container">
            <div className="row align-items-center justify-content-xl-between">
              <div className="col-xl-6">
                <div className="copyright text-center text-xl-left text-muted">
                  © 2021{" "}
                  <a
                    // href="https://www.creative-tim.com/product/argon-dashboard"
                    className="font-weight-bold ml-1"
                    target="_blank"
                  >
                    Little Hearts
                  </a>
                </div>
              </div>
              <div className="col-xl-6">
                <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                  <li className="nav-item">
                    <a
                      //   href="https://www.creative-tim.com"
                      className="nav-link"
                      target="_blank"
                    >
                      Little Hearts
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      //   href="https://www.creative-tim.com/presentation"
                      className="nav-link"
                      target="_blank"
                    >
                      About Us
                    </a>
                  </li>
                  {/* <li className="nav-item">
                        <a
                          //   href="http://blog.creative-tim.com"
                          className="nav-link"
                          target="_blank"
                        >
                          Blog
                        </a>
                      </li> */}
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  };

  return (
    <>
      {errorMessage()}
      {resetPasswordForm()}
    </>
  );
};

export default ResetPassword;
