import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const Chart3 = ({ chartData }) => {
  const [labelsChart, setLabelsChart] = useState([]);

  useEffect(() => {
    let labels = [];
    let hoursCount = 0;

    let day = new Date(Date.now());
    let time = day.getHours();

    while (hoursCount < 6) {
      let prevTime = time - hoursCount;

      labels.push(`${prevTime} hrs`);
      hoursCount++;
    }

    labels.reverse();
    setLabelsChart(labels);
  }, []);

  return (
    <Line
      data={{
        // labels: ["1 Hour", "2 Hour", "3 Hour", "4 Hour", "5 Hour", "6 Hour"],
        labels: labelsChart,
        datasets: [
          {
            label: "Total hearts obtained",
            fill: true,
            // backgroundColor: gradientStroke,
            // backgroundColor: "#5e72e4",
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // data: [100, 70, 90, 70, 85, 122],
            // data: [60, 75, 60, 90, 80, 110, 100],
            data: chartData,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent",
              },
              ticks: {
                suggestedMin: 60,
                suggestedMax: 125,
                padding: 20,
                fontColor: "#9a9a9a",
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a",
              },
            },
          ],
        },
      }}
    />
  );
};

export default Chart3;
