import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { isSignedIn } from "../apiCalls/apiCalls";
import { read_cookie } from "sfcookies";
import cookie from "react-cookie";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const [userInfo, setUserInfo] = useState(undefined);
  // const [userInfoLH, setUserInfoLH] = useState([]);

  // const [userName, setUserName] = useState("");
  // const [length, setLength] = useState(0);

  // const [permission, setPermission] = useState(false);

  let permission = false;
  let permission1 = false;
  let permission2 = false;
  let permission3 = false;

  // useEffect(() => {
  // setUserInfo(read_cookie("userInfo"));
  // setUserInfoLH(cookie.load("userInfoLH"));

  const userInfo = read_cookie("userInfo");
  const userInfoLH = cookie.load("userInfoLH");

  // setTimeout(() => {
  // console.log("info", userInfo);
  // console.log("LHinfo", userInfoLH);

  if (
    (userInfo === undefined || userInfo.length === 0) &&
    (userInfoLH === undefined || userInfoLH.length === 0)
  ) {
    permission1 = true;
    console.log("p1");
  }

  let userName = "";
  let length = 0;

  if (userInfo !== undefined && userInfo.length !== 0) {
    // console.log("Here1");

    userName = userInfo.userName;
    userName = userName.substring(0, 5);

    if (userName === "GUEST") {
      permission2 = true;
      console.log("p2");
    }
    // setUserName(userInfo.userName);
    // setUserName(userName.substring(0, 5));
  }

  if (userInfoLH !== undefined && userInfoLH.length !== 0) {
    // console.log("Here2");

    length = userInfoLH.length;
    permission3 = true;
    // setLength(userInfoLH.length);

    console.log("p3");
  }

  // setPermission(

  // permission =
  // (length === 0 && (userInfo === undefined || userInfo.length !== 0)) ||
  // userName === "GUEST" ||
  // length;

  // );
  // }, 700);

  permission = permission1 || permission2 || permission3;

  // console.log(permission);

  // console.log(userName);
  // console.log(userInfoLH);
  // }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        permission ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/admin/userdashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
