import React, { useEffect, useState } from "react";
import { read_cookie, bake_cookie } from "sfcookies";
import { youtubeContentVerificationResponse } from "apiCalls/apiCalls";
import { useHistory } from "react-router";
import { websiteVerification } from "apiCalls/apiCalls";
import uuid from "react-uuid";

import isValidDomain from "is-valid-domain";
import extractDomain from "extract-domain";
import dns from "dns";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Alert,
} from "reactstrap";
import { generateTxtRecord } from "apiCalls/apiCalls";
import { updateGuestAcc } from "apiCalls/apiCalls";

const WebsiteVerification = () => {
  const user = read_cookie("userInfo");
  const token = read_cookie("userToken");

  const [domain, setDomain] = useState("");
  const [txtRec, setTxtRec] = useState("");

  const [generate, setGenerate] = useState(true);
  const [verify, setVerify] = useState(false);

  const [visible, setVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");

  const history = useHistory();

  const generateTxt = () => {
    setTxtRec("");
    setVisible(false);

    // var extractDomain = require("extract-domain")
    // var isValidDomain = require("is-valid-domain")
    // let url = "meet.nbot.live";

    let url = domain;

    if (url.includes("http://") || url.includes("https://")) {
      url = url.replace("http://", "");
      url = url.replace("https://", "");
    }

    if (!isValidDomain(url, { subdomain: true })) {
      setAlertColor("warning");
      setAlertText("You entered invalid domain!");
      setVisible(true);
      return;
    }

    url = "http://" + url;

    // if (isValidDomain(url)) {
    //   extractDomain("http://" + url);
    // }

    const generateTxtObject = {
      // get proper npm package to take out www., docs. etc
      // domainName: domain.replace("www.", "").toLowerCase(),
      domainName: extractDomain(url).toLowerCase(),
    };

    generateTxtRecord(token, generateTxtObject).then((data) => {
      if (data.error) {
        console.log("Error", data.error);

        setVisible(false);
        setAlertColor("danger");
        setAlertText(data.error);
        // setAlertText("Error occured in verification! Try afgain in some time");
        setVisible(true);
      } else {
        console.log(data);

        setTxtRec(data.txtInfo);

        setVisible(false);
        setAlertColor("success");
        setAlertText("Txt Record generated successfully");
        // setAlertText("Error occured in verification! Try afgain in some time");
        setVisible(true);

        setGenerate(false);
        setVerify(true);
      }
    });
  };

  const verifyWebsite = () => {
    const domainName = document.getElementById("domainName");

    setVisible(false);
    setAlertColor("success");
    setAlertText("Verification under process...");
    setVisible(true);

    let url = domain;

    if (!url.includes("http://") && !url.includes("https://")) {
      url = "http://" + url;
    }

    let body = {
      // get proper npm package to take out www., docs. etc
      // domainName: domain.replace("www.", "").toLowerCase(),
      domainName: extractDomain(url).toLowerCase(),
      txtRecord: txtRec,
    };

    websiteVerification(token, body).then((data) => {
      if (data.error) {
        console.log("Error", data.error);

        if (
          data.error ===
          "The required txt record not found in your website's DNS panel!"
        ) {
          setVisible(false);
          setAlertColor("danger");
          setAlertText(data.error);
          // setAlertText("Error occured in verification! Try afgain in some time");
          setVisible(true);

          // domainName.value = "";
          // setDomain("");
          // setTxtRec("");

          setGenerate(false);
          setVerify(true);
        } else {
          setVisible(false);
          setAlertColor("danger");
          setAlertText(data.error);
          // setAlertText("Error occured in verification! Try afgain in some time");
          setVisible(true);

          domainName.value = "";
          setDomain("");
          setTxtRec("");

          setVerify(false);
          setGenerate(true);
        }
      } else {
        console.log(data);

        let body = {
          id: user._id,
          user: { creatorAccount: true },
        };

        updateGuestAcc(body)
          .then((data) => {
            if (data.error) {
              console.log("Error", data.error);
            } else {
              bake_cookie("userInfo", data);

              console.log("Creator account value is changed");
            }
          })
          .catch(console.log("Creator account updated"));

        setVisible(false);
        setAlertColor("success");
        setAlertText(data.msg);
        setVisible(true);

        domainName.value = "";
        setDomain("");
        setTxtRec("");

        setVerify(false);
        setGenerate(true);

        setTimeout(() => {
          history.push("/admin/contentverification");
        }, process.env.SETTIMEOUT5);
      }
    });
  };

  // URL sanitization
  return (
    <>
      <div
        className="content py-4"
        // className="content d-flex flex-column justify-content-center align-items-center"
        style={{ width: "98vw", height: "100vh" }}
      >
        <Row
          style={{ width: "100%", position: "fixed", top: "5%", zIndex: 15 }}
        >
          <Col className="ml-auto mr-auto" md="6">
            <Alert
              color={alertColor}
              isOpen={visible}
              toggle={() => {
                setVisible(false);
              }}
            >
              <span>{alertText}</span>
            </Alert>
          </Col>
        </Row>
        <Row style={{ width: "80%", margin: "auto" }}>
          <Col md="12">
            <Card>
              <CardHeader>
                <h2 className="title text-center">Website Verification</h2>
              </CardHeader>
              {/* <CardBody className="px-4"> */}
              <CardBody>
                <Row>
                  <blockquote className="px-3">
                    <p className="blockquote blockquote-primary text-warning text-center">
                      {/* <p className="blockquote"> */}
                      Add a new TXT record for website you want to verify. To
                      verify ownership of your site, you'll need to add a new
                      DNS record for your website using the account for your DNS
                      host. Please add the record on your root domain, not a
                      subdomain. Now enter your domain name below. Make sure you
                      enter valid domain name!
                      {/* <small>- Noaa</small> */}
                    </p>
                  </blockquote>
                </Row>
                <Form autoComplete="off">
                  <Row>
                    <Col className="px-3" md="12">
                      <FormGroup>
                        <label>Domain</label>
                        <Input
                          defaultValue=""
                          placeholder="Enter your Domain..."
                          type="text"
                          id="domainName"
                          onChange={(event) => {
                            setDomain(event.target.value);

                            setVerify(false);
                            setGenerate(true);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                {/* <Row> */}
                <blockquote>
                  {/* <blockquote className="px-3"> */}
                  <p className="blockquote blockquote-primary text-success text-center">
                    {/* <p className="blockquote"> */}
                    Then click on Generate Txt Record button. Copy and paste the
                    info below into the zone file for your website using your
                    DNS host's control panel.
                  </p>
                </blockquote>
                {/* </Row> */}
                <Form>
                  <Row>
                    <Col className="px-3" md="12">
                      <FormGroup>
                        <label>Your TXT Record will appear here</label>
                        <Input
                          defaultValue=""
                          placeholder="Txt Record"
                          type="text"
                          value={txtRec}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                {/* <Row> */}
                <blockquote>
                  {/* <blockquote className="px-3"> */}
                  <p className="blockquote blockquote-primary text-danger text-center">
                    {/* <p className="blockquote"> */}
                    After adding and saving this TXT record with your DNS host,
                    click the Verify Website button below to verify. <br />
                    Note: This may take a few minutes to several hours to become
                    available for verification depending on where your DNS is
                    hosted.
                  </p>
                </blockquote>
                {/* </Row> */}
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <Button
                      className="btn-fill"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        history.push("/admin/contentverification");
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col>
                    {generate && (
                      <Button
                        className="btn-fill"
                        color="success"
                        type="submit"
                        onClick={generateTxt}
                      >
                        Generate Txt Record
                      </Button>
                    )}
                    {verify && (
                      <Button
                        className="btn-fill"
                        color="danger"
                        type="submit"
                        onClick={verifyWebsite}
                      >
                        Verify Website
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col md="12">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/emilyz.jpg").default}
                    />
                    <h5 className="title">Little Hearts</h5>
                  </a>
                  <p className="description">Little Hearts</p>
                </div>
                <div className="card-description">Dummy text</div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default WebsiteVerification;
