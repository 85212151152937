/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { getUser } from "apiCalls/apiCalls";
import { isSignedIn } from "apiCalls/apiCalls";
import { bake_cookie, read_cookie, delete_cookie } from "sfcookies";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router";
import { updateGuestAcc2 } from "apiCalls/apiCalls";

function UserProfile() {
  // const { user, token } = isSignedIn();
  const user = read_cookie("userInfo");
  const token = read_cookie("userToken");

  const [userName, setUserName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");

  const [visible, setVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (!user || !token) {
      console.log("HERE");
      return history.push("/");
    }

    getUser(token, user._id).then((data) => {
      if (data.error) {
        console.log("Error", data.error);
      } else {
        // console.log(data);
        setUserName(data.userName);
        setEmail(data.email);
        setDisplayName(data.displayName ? data.displayName : "");
        setAge(data.age ? data.age : "");
        setGender(data.gender ? data.gender : "");
      }
    });
  }, []);

  const updateInfo = () => {
    console.log(age);
    if (age !== "" && age <= 0) {
      setVisible(false);
      setAlertColor("danger");
      setAlertText("Age cannot be negative or zero");
      setVisible(true);
    } else if (age === "" || age > 0) {
      if (gender === "M" || gender === "F" || gender === "O" || gender === "") {
        let body = {
          id: user._id,
          user: {
            userName: userName,
            email: email,
            displayName: displayName,
            age: age,
            gender: gender,
          },
        };

        updateGuestAcc2(body)
          .then((data) => {
            if (data.error) {
              console.log("Error", data.error);

              setVisible(false);
              setAlertColor("danger");
              setAlertText(data.error);
              setVisible(true);
            } else {
              bake_cookie("userInfo", data);

              setVisible(false);
              setAlertColor("success");
              setAlertText("Details updated successfully!");
              setVisible(true);
            }
          })
          .catch(console.log("Creator account updated"));
      } else {
        setVisible(false);
        setAlertColor("danger");
        setAlertText("Gender should me M or F or O!");
        setVisible(true);
      }
    }
  };

  return (
    <>
      <div className="content">
        <Row style={{ width: "80%", position: "fixed", top: "8%", zIndex: 15 }}>
          <Col className="ml-auto mr-auto" md="6">
            <Alert
              color={alertColor}
              isOpen={visible}
              toggle={() => {
                setVisible(false);
              }}
            >
              <span>{alertText}</span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    {/* <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Company (disabled)</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col> */}
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          defaultValue={userName}
                          placeholder="Username"
                          type="text"
                          // value={userName}
                          onChange={(event) => {
                            setUserName(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          defaultValue={email}
                          placeholder="Email"
                          type="email"
                          // value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Display Name</label>
                        <Input
                          defaultValue={displayName}
                          placeholder="Display Name"
                          type="text"
                          onChange={(event) => {
                            setDisplayName(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Age</label>
                        <Input
                          defaultValue={age}
                          placeholder="Age"
                          type="number"
                          onChange={(event) => {
                            setAge(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Gender [M/F/O]</label>
                        <Input
                          defaultValue={gender}
                          placeholder="Gender"
                          type="text"
                          onChange={(event) => {
                            setGender(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input defaultValue="" placeholder="City" type="text" />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue=""
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="ZIP Code" type="number" />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={updateInfo}
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/emilyz.jpg").default}
                    />
                    <h5 className="title">Little Hearts</h5>
                  </a>
                  <p className="description">Little Hearts</p>
                </div>
                <div className="card-description">Dummy text</div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
