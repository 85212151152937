/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  Table,
  FormGroup,
  Form,
  Input,
  Alert,
  Button,
} from "reactstrap";

// import { Redirect } from "react-router-dom";
import { Link, Redirect, useHistory } from "react-router-dom";
import { read_cookie } from "sfcookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faYoutube } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import {
  isSignedIn,
  youtubeContentVerification,
  youtubeContentVerificationResponse,
  getClaimedContents,
  updateMessageToConsumers,
  unclaimContent,
} from "../apiCalls/apiCalls.js";

import "../assets/manualCSS/card.css";

function ClaimContent() {
  // const { user, token } = isSignedIn();
  const user = read_cookie("userInfo");
  const token = read_cookie("userToken");

  const [claimedContents, setClaimedContents] = useState();
  const [iconColor, setIconColor] = useState("white");

  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");
  const [visible, setVisible] = useState(false);

  let history = useHistory();

  useEffect(() => {
    getClaimedContents(token).then((data) => {
      if (data.error) {
        console.log("Error", data.error);
      } else {
        // console.log(data);
        setClaimedContents(data);
      }
    });

    const lightModeLH = document.getElementById("lightModeLH");
    const darkModeLH = document.getElementById("darkModeLH");

    lightModeLH.onclick = () => {
      setIconColor("#27293d");
    };

    darkModeLH.onclick = () => {
      setIconColor("white");
    };
  }, []);

  const handelYoutubeVerification = () => {
    youtubeContentVerification(token).then((data) => {
      if (data.error) {
        console.log("Error", data.error);
      } else {
        window.open(data, "_blank");
      }
    });
  };

  return (
    <>
      <div className="content">
        <Row
          className="d-flex align-items-center justify-content-center"
          style={{ width: "80%", position: "fixed", top: "8%", zIndex: 15 }}
        >
          <Col className="ml-auto mr-auto" md="6">
            <Alert
              color={alertColor}
              isOpen={visible}
              toggle={() => {
                setVisible(false);
              }}
            >
              <span>{alertText}</span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h2 className="title text-center">Claim Content</h2>
                <p className="category text-center">
                  Choose the platform where your content stands!
                </p>
              </CardHeader>
              <CardBody className="all-icons">
                <Row>
                  {/* <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-alert-circle-exc" />
                      <p>icon-alert-circle-exc</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-align-center" />
                      <p>icon-align-center</p>
                    </div>
                  </Col> 
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-align-left-2" />
                      <p>icon-align-left-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-app" />
                      <p>icon-app</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-atom" />
                      <p>icon-atom</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-attach-87" />
                      <p>icon-attach-87</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-badge" />
                      <p>icon-badge</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bag-16" />
                      <p>icon-bag-16</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bank" />
                      <p>icon-bank</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-basket-simple" />
                      <p>icon-basket-simple</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bell-55" />
                      <p>icon-bell-55</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bold" />
                      <p>icon-bold</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-book-bookmark" />
                      <p>icon-book-bookmark</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-double-right" />
                      <p>icon-double-right</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bulb-63" />
                      <p>icon-bulb-63</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bullet-list-67" />
                      <p>icon-bullet-list-67</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-bus-front-12" />
                      <p>icon-bus-front-12</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-button-power" />
                      <p>icon-button-power</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-camera-18" />
                      <p>icon-camera-18</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-calendar-60" />
                      <p>icon-calendar-60</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-caps-small" />
                      <p>icon-caps-small</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-cart" />
                      <p>icon-cart</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-chart-bar-32" />
                      <p>icon-chart-bar-32</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-chart-pie-36" />
                      <p>icon-chart-pie-36</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-chat-33" />
                      <p>icon-chat-33</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-check-2" />
                      <p>icon-check-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-cloud-download-93" />
                      <p>icon-cloud-download-93</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-cloud-upload-94" />
                      <p>icon-cloud-upload-94</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-coins" />
                      <p>icon-coins</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-compass-05" />
                      <p>icon-compass-05</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-controller" />
                      <p>icon-controller</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-credit-card" />
                      <p>icon-credit-card</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-delivery-fast" />
                      <p>icon-delivery-fast</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-email-85" />
                      <p>icon-email-85</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-gift-2" />
                      <p>icon-gift-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-globe-2" />
                      <p>icon-globe-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-headphones" />
                      <p>icon-headphones</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-heart-2" />
                      <p>icon-heart-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-html5" />
                      <p>icon-html5</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-double-left" />
                      <p>icon-double-left</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-image-02" />
                      <p>icon-image-02</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-istanbul" />
                      <p>icon-istanbul</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-key-25" />
                      <p>icon-key-25</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-laptop" />
                      <p>icon-laptop</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-light-3" />
                      <p>icon-light-3</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-link-72" />
                      <p>icon-link-72</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-lock-circle" />
                      <p>icon-lock-circle</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-map-big" />
                      <p>icon-map-big</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-minimal-down" />
                      <p>icon-minimal-down</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-minimal-left" />
                      <p>icon-minimal-left</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-minimal-right" />
                      <p>icon-minimal-right</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-minimal-up" />
                      <p>icon-minimal-up</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-mobile" />
                      <p>icon-mobile</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-molecule-40" />
                      <p>icon-molecule-40</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-money-coins" />
                      <p>icon-money-coins</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-notes" />
                      <p>icon-notes</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-palette" />
                      <p>icon-palette</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-paper" />
                      <p>icon-paper</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-pin" />
                      <p>icon-pin</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-planet" />
                      <p>icon-planet</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-puzzle-10" />
                      <p>icon-puzzle-10</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-pencil" />
                      <p>icon-pencil</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-satisfied" />
                      <p>icon-satisfied</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-scissors" />
                      <p>icon-scissors</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-send" />
                      <p>icon-send</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-settings-gear-63" />
                      <p>icon-settings-gear-63</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-settings" />
                      <p>icon-settings</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-wifi" />
                      <p>icon-wifi</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-single-02" />
                      <p>icon-single-02</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-single-copy-04" />
                      <p>icon-single-copy-04</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-sound-wave" />
                      <p>icon-sound-wave</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-spaceship" />
                      <p>icon-spaceship</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-square-pin" />
                      <p>icon-square-pin</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-support-17" />
                      <p>icon-support-17</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-tablet-2" />
                      <p>icon-tablet-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-tag" />
                      <p>icon-tag</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-tap-02" />
                      <p>icon-tap-02</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-tie-bow" />
                      <p>icon-tie-bow</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-time-alarm" />
                      <p>icon-time-alarm</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-trash-simple" />
                      <p>icon-trash-simple</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-trophy" />
                      <p>icon-trophy</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-tv-2" />
                      <p>icon-tv-2</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-upload" />
                      <p>icon-upload</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-user-run" />
                      <p>icon-user-run</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-vector" />
                      <p>icon-vector</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-video-66" />
                      <p>icon-video-66</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-wallet-43" />
                      <p>icon-wallet-43</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-volume-98" />
                      <p>icon-volume-98</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-watch-time" />
                      <p>icon-watch-time</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-world" />
                      <p>icon-world</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-zoom-split" />
                      <p>icon-zoom-split</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-refresh-01" />
                      <p>icon-refresh-01</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-refresh-02" />
                      <p>icon-refresh-02</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-shape-star" />
                      <p>icon-shape-star</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i className="tim-icons icon-components" />
                      <p>icon-components</p>
                    </div>
                  </Col>*/}
                  {/* <Col 
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="12"
                  >

                  </Col> */}
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div
                      className="font-icon-detail"
                      onClick={handelYoutubeVerification}
                    >
                      {/* <i className="tim-icons icon-badge" /> */}
                      <FontAwesomeIcon
                        className="tim-icons"
                        icon={faYoutube}
                        style={{
                          color: iconColor,
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <p>Youtube</p>
                    </div>
                  </Col>
                  {/* <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      {/* <i className="tim-icons icon-badge" /> *
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="iconManual"
                        style={{
                          color: iconColor,
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <p>Twitter</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      {/* <i className="tim-icons icon-badge" /> *
                      <FontAwesomeIcon
                        icon={faGithub}
                        className="iconManual"
                        style={{
                          color: iconColor,
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <p>GitHub</p>
                    </div>
                  </Col> */}
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div
                      className="font-icon-detail"
                      onClick={() => {
                        history.push("/websiteVerification");
                      }}
                    >
                      <i
                        className="tim-icons icon-badge"
                        style={{
                          color: iconColor,
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <p>Website</p>
                    </div>
                  </Col>
                  {/* <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i
                        className="tim-icons icon-badge"
                        style={{
                          color: iconColor,
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <p>Website</p>
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                      <i
                        className="tim-icons icon-badge"
                        style={{
                          color: iconColor,
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <p>Website</p>
                    </div>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Contents Claimed
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Content Platform</th>
                      {/* <th>Country</th> */}
                      {/* <th>City</th> */}
                      <th className="text-center">Content ID</th>
                      <th className="text-center">Message to Consumers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {claimedContents &&
                      claimedContents.map((content, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center" id={`type-${index}`}>
                              {content.contentType}
                            </td>
                            <td className="text-center" id={`id-${index}`}>
                              {content.contentPlatformId}
                            </td>
                            <td>
                              <Form>
                                <FormGroup>
                                  <Input
                                    defaultValue={content.messageToConsumers}
                                    placeholder="Message"
                                    type="text"
                                    id={`msg-${index}`}
                                    // value={content.messageToConsumers}
                                    // onBlur={(event) => {
                                    //   // console.log(
                                    //   //   "OnBlur",
                                    //   //   claimedContents[index]
                                    //   // );
                                    //   // console.log(
                                    //   //   "OnBlur2",
                                    //   //   event.target.value
                                    //   // );

                                    //   let requestBody = {
                                    //     contentPlatformID:
                                    //       claimedContents[index]
                                    //         .contentPlatformId,
                                    //     message: event.target.value,
                                    //   };

                                    //   updateMessageToConsumers(
                                    //     token,
                                    //     requestBody
                                    //   ).then((data) => {
                                    //     if (data.error) {
                                    //       console.log("Error", data.error);

                                    //       setVisible(false);
                                    //       setAlertColor("danger");
                                    //       setAlertText("Message not saved!");
                                    //       setVisible(true);
                                    //     } else {
                                    //       console.log(data);

                                    //       setVisible(false);
                                    //       setAlertColor("success");
                                    //       setAlertText("Message saved!");
                                    //       setVisible(true);
                                    //     }
                                    //   });
                                    // }}
                                  />
                                </FormGroup>
                              </Form>
                            </td>
                            <td>
                              <Button
                                className="btn"
                                color="success"
                                type="submit"
                                onClick={(event) => {
                                  event.preventDefault();

                                  let requestBody = {
                                    contentPlatformID:
                                      claimedContents[index].contentPlatformId,
                                    message: document.getElementById(
                                      `msg-${index}`
                                    ).value,
                                  };

                                  updateMessageToConsumers(
                                    token,
                                    requestBody
                                  ).then((data) => {
                                    if (data.error) {
                                      console.log("Error", data.error);

                                      setVisible(false);
                                      setAlertColor("danger");
                                      setAlertText(
                                        "Message not Published! Try again after some time."
                                      );
                                      setVisible(true);
                                    } else {
                                      console.log(data);

                                      setVisible(false);
                                      setAlertColor("success");
                                      setAlertText("Message Published!");
                                      setVisible(true);
                                    }
                                  });
                                }}
                                style={{
                                  padding: "10px 8px",
                                  fontWeight: "normal",
                                }}
                              >
                                Publish
                              </Button>
                              {/* </td>
                            <td> */}
                              <Button
                                className="btn"
                                color="danger"
                                type="submit"
                                onClick={(event) => {
                                  event.preventDefault();

                                  let requestBody = {
                                    contentID: document.getElementById(
                                      `id-${index}`
                                    ).innerText,
                                    contentType: document.getElementById(
                                      `type-${index}`
                                    ).innerText,
                                  };

                                  console.log(requestBody);

                                  unclaimContent(token, requestBody).then(
                                    (data) => {
                                      if (data.error) {
                                        console.log("Error", data.error);

                                        setVisible(false);
                                        setAlertColor("danger");
                                        setAlertText(data.error);
                                        // setAlertText(
                                        //   "Message not Published! Try again after some time."
                                        // );
                                        setVisible(true);
                                      } else {
                                        console.log(data);

                                        setVisible(false);
                                        setAlertColor("success");
                                        setAlertText(data.msg);
                                        setVisible(true);

                                        getClaimedContents(token).then(
                                          (data) => {
                                            if (data.error) {
                                              console.log("Error", data.error);
                                            } else {
                                              // console.log(data);
                                              setClaimedContents(data);
                                            }
                                          }
                                        );
                                        // setTimeout(() => {
                                        //   window.location.reload(false);
                                        // }, process.env.SETTIMEOUT3);
                                      }
                                    }
                                  );
                                }}
                                style={{
                                  padding: "10px 8px",
                                  marginLeft: "10px",
                                  fontWeight: "normal",
                                }}
                              >
                                Unclaim
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ClaimContent;
