/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Chart1 from "../variables/7daysInfo";
import Chart2 from "../variables/15daysInfo";
import Chart3 from "../variables/pastHours";
import Chart4 from "variables/topContents";
import { LittleHeartsAPI } from "backend";
import {
  getContentCreatorDashboard,
  getPreviousHoursHearts,
  getPreviousDaysHearts,
  getTopContents,
  getCountrywiseData,
  isSignedIn,
} from "../apiCalls/apiCalls";
import { Cookies } from "react-cookie";
import { read_cookie } from "sfcookies";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Geographical chart
import { Chart } from "react-google-charts";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";

// Manual CSS
import "../assets/manualCSS/dashboards.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function Dashboard() {
  // const [bigChartData, setbigChartData] = React.useState("data1");
  const [chartDataSmall, setChartDataSmall] = useState([]);
  const [chartDataMedium, setChartDataMedium] = useState([]);
  const [chartDataBig, setChartDataBig] = useState([]);

  const [topContentsDisplay, setTopContentsDisplay] = useState([]);
  const [topContents, setTopContents] = useState([]);
  const [topContentsHearts, setTopContentsHearts] = useState([]);
  const [geoGraphData, setGeoGraphData] = useState([[]]);
  const [countryTableData, setCountryTableData] = useState([]);

  const [graphBG, setGraphBG] = useState("#27293d");

  // const [mapRef] = useRef("globeData");

  // let trying = [["Country", "Hearts"]];
  let trying = [["Country", "Popularity"]];
  // let countryTableData = [];

  const [hours, setHours] = useState(true);
  const [days7, setDays7] = useState(false);
  const [days15, setDays15] = useState(false);

  // const setBgChartData = (name) => {
  // setbigChartData(name);
  // };

  // const { user, token } = isSignedIn();
  const user = read_cookie("userInfo");
  const token = read_cookie("userToken");

  const history = useHistory();

  // const signInCheck = () => {
  //   console.log("HERE1");
  //   if (!user.length || !token.length) {
  //     console.log("HERE");
  //     return history.push("/");
  //   }
  // };

  useEffect(() => {
    // signInCheck();
    // console.log(Cookies.get("userToken"));
    // console.log(user);
    // console.log(token);

    trying = [];
    // countryTableData = [];

    getPreviousHoursHearts(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        // let reverse = data.totalHeartsPrevHours;
        // reverse = reverse.reverse();

        // setChartDataSmall(reverse);
        setChartDataSmall(data.totalHeartsPrevHours);
      }
    });

    getPreviousDaysHearts(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        setChartDataBig(data.totalHeartsPrevDays);

        // let reverse = data.totalHeartsPrevDays;
        // reverse = reverse.reverse();
        // setChartDataMedium(reverse);

        let tempArr = [];
        let count = 0;

        for (
          let index = data.totalHeartsPrevDays.length - 1;
          index >= 0 && count < 7;
          index--
        ) {
          const element = data.totalHeartsPrevDays[index];
          tempArr.push(element);

          count++;
        }

        tempArr.reverse();
        setChartDataMedium(tempArr);
      }
    });

    getTopContents(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        console.log(data.dayWiseHeartsGiven);
        let topContentsDisplayTemp = [];

        for (let i = 0; i < data.dayWiseHeartsGiven.length && i < 50; i++) {
          topContentsDisplayTemp.push(data.dayWiseHeartsGiven[i]);
        }

        setTopContentsDisplay(topContentsDisplayTemp);

        let topPerformingContentsTemp = [];
        let topPerformingContentsHeartsTemp = [];

        data.topPerformingContents.forEach((object) => {
          topPerformingContentsTemp.push(object.contentTitle);
          topPerformingContentsHeartsTemp.push(object.hearts);
        });

        setTopContents(topPerformingContentsTemp);
        setTopContentsHearts(topPerformingContentsHeartsTemp);
      }
    });

    getCountrywiseData(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        setGeoGraphData(data.countryWiseData);
        // geoGraphData = geoGraphData.json();

        // console.log(data.countryWiseData);
        // console.log(geoGraphData);

        data.countryWiseData.forEach((object) => {
          trying.push(object);

          let obj = {
            country: object[0],
            hearts: object[1],
          };

          // countryTableData.push(obj);
        });

        // console.log(countryTableData);
        setCountryTableData(data.countryTableData);

        // console.log(trying);
      }
    });

    const lightModeLH = document.getElementById("lightModeLH");
    const darkModeLH = document.getElementById("darkModeLH");

    lightModeLH.onclick = () => {
      setGraphBG("white");
      // setGraphBG("whitesmoke");
      // setGraphBG("#efefef");
    };

    darkModeLH.onclick = () => {
      setGraphBG("#27293d");
    };

    // getContentCreatorDashboard(token).then((data) => {
    //   if (data.error) {
    //     console.log("Error is", data.error);
    //   } else {
    //     console.log(data);
    //     setChartDataBig(data.totalHeartsPrevDays);
    //     setTopContentsDisplay(data.topPerformingContents);
    //     setGeoGraphData(data.countryWiseData);

    //     console.log(data.countryWiseData);
    //     console.log(geoGraphData);

    //     // trying.push(data.countryWiseData);

    //     data.countryWiseData.forEach((object) => {
    //       trying.push(object);
    //     });

    //     console.log(trying);

    //     let topPerformingContentsTemp = [];
    //     let topPerformingContentsHeartsTemp = [];

    //     data.topPerformingContents.forEach((object) => {
    //       topPerformingContentsTemp.push(object.content);
    //       topPerformingContentsHeartsTemp.push(object.hearts);
    //     });

    //     setTopContents(topPerformingContentsTemp);
    //     setTopContentsHearts(topPerformingContentsHeartsTemp);
    //   }
    // });
  }, []);

  // useEffect(() => {
  //   google.charts.load("current", {
  //     packages: ["geochart"],
  //     // Note: you will need to get a mapsApiKey for your project.
  //     // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
  //     mapsApiKey: "AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY",
  //   });
  //   google.charts.setOnLoadCallback(drawRegionsMap);

  //   function drawRegionsMap() {
  //     var data = google.visualization.arrayToDataTable([
  //       ["Country", "Popularity"],
  //       ["Germany", 200],
  //       ["United States", 300],
  //       ["Brazil", 400],
  //       ["Canada", 500],
  //       ["France", 600],
  //       ["RU", 700],
  //     ]);

  //     var options = {};

  //     var chart = new google.visualization.GeoChart(
  //       document.getElementById("regions_div")
  //     );

  //     chart.draw(data, options);
  //   }
  // }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Hey {user.userName}! See your contents Overview
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Date</th>
                      <th>Content</th>
                      {/* <th>Country</th> */}
                      {/* <th>City</th> */}
                      <th className="text-center">Hearts Obtained</th>
                      <th className="text-center">Unique Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topContentsDisplay.map((object, index, helperArray) => {
                      // const { hostname } = new URL(object.content);
                      let day;

                      if (
                        index > 0 &&
                        helperArray[index].day === helperArray[index - 1].day
                      ) {
                        day = "";
                      } else {
                        let temp = new Date(object.day);
                        let today = new Date(Date.now());
                        let yesterday = new Date(
                          Date.now() - 1 * 24 * 60 * 60 * 1000
                        );

                        if (today.getDate() === temp.getDate()) {
                          day = "Today";
                        } else if (yesterday.getDate() === temp.getDate()) {
                          day = "Yesterday";
                        } else {
                          day = `${temp.getDate()}-${
                            temp.getMonth() + 1
                          }-${temp.getFullYear()}`;
                        }
                      }
                      const hostname = object.contentTitle;

                      return (
                        // <tr key={index} className="manualColors">
                        <tr key={index}>
                          <td>{day}</td>
                          <td className="text-white">
                            <a href={object.content} title={object.content}>
                              {hostname}
                            </a>
                            {/* <Link to={object.content} title={object.content}>
                              {hostname}
                            </Link> */}
                          </td>
                          <td className="text-center text-white">
                            {object.hearts}
                          </td>
                          <td className="text-center text-white">
                            {object.uniqueHeartsGiver}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Content Performances</h5>
                    <CardTitle tag="h2">Recent Performance</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: hours,
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => {
                          setHours(true);
                          setDays7(false);
                          setDays15(false);
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Past hours
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: days7,
                        })}
                        onClick={() => {
                          setHours(false);
                          setDays7(true);
                          setDays15(false);
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          7 Days
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: days15,
                        })}
                        onClick={() => {
                          setHours(false);
                          setDays7(false);
                          setDays15(true);
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          15 Days
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {hours && <Chart3 chartData={chartDataSmall} />}
                  {days7 === true && <Chart1 chartData={chartDataMedium} />}
                  {days15 === true && <Chart2 chartData={chartDataBig} />}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Top performing contents</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-heart-2 text-primary" /> Hearts
                  obtained
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {/* <Bar
                    data={chartExample3.data}
                    options={chartExample3.options}
                  /> */}
                  <Chart4 labels={topContents} data={topContentsHearts} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            {/* <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Completed Tasks</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> 12,100K
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample4.data}
                    options={chartExample4.options}
                  />
                </div>
              </CardBody>
            </Card> */}
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  Likes obtained accross the globe
                </h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-heart-2 text-info" /> Hearts
                  Obtained
                </CardTitle>
              </CardHeader>
              <Row>
                <Col lg="5">
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Country</th>
                        {/* <th>Country</th> */}
                        {/* <th>City</th> */}
                        <th className="text-center">Hearts obtained</th>
                      </tr>
                    </thead>
                    {/* {trying &&
                      trying.forEach((array, index) => {
                        return (
                          <tbody key={index}>
                            <td className="text-center">{array[0]}</td>
                            <td className="text-center">{array[1]}</td>
                          </tbody>
                        );
                      })} */}
                    {/* <tbody>
                      <td className="text-center">USA</td>
                      <td className="text-center">50</td>
                    </tbody>
                    <tbody>
                      <td className="text-center">IND</td>
                      <td className="text-center">14</td>
                    </tbody>
                    <tbody>
                      <td className="text-center"> SL</td>
                      <td className="text-center">78</td>
                    </tbody>
                    <tbody>
                      <td className="text-center">PAK</td>
                      <td className="text-center">723</td>
                    </tbody> */}
                    <tbody>
                      {countryTableData.map((object, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center ">
                              {/* <span className="text-white"> */}
                              {object.country}
                              {/* </span> */}
                            </td>
                            <td className="text-center ">
                              {/* <span className="text-white"> */}
                              {object.hearts}
                              {/* </span> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>

                <Col lg="7">
                  <CardBody>
                    <div className="chart-area">
                      {/* <Line
                    data={chartExample2.data}
                    options={chartExample2.options}
                  /> */}
                      {/* <div
                    id="regions_div"
                    style={{ width: "900px", height: "500px" }}
                  ></div> */}
                      <Chart
                        // ref={mapRef}
                        id="geoGraph"
                        width={"100%"}
                        height={"100%"}
                        chartType="GeoChart"
                        // data={[
                        //   ["Country", "Hearts Obtained"],
                        //   ["Germany", 200],
                        //   ["United States", 300],
                        //   ["Brazil", 400],
                        //   ["Canada", 500],
                        //   ["France", 600],
                        //   ["RU", 700],
                        // ]}
                        data={geoGraphData}
                        // data={trying}
                        options={{
                          // region: "002", // Africa
                          colorAxis: {
                            colors: ["#dadada", "#b9b9b9", "grey"],
                          },
                          // colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
                          backgroundColor: graphBG,
                          // backgroundColor: "black",
                          datalessRegionColor: "white",
                          defaultColor: "#f5f5f5",
                        }}
                        // Note: you will need to get a mapsApiKey for your project.
                        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                        mapsApiKey="AIzaSyBkqoU-RoDe8YkXnDQc9AaoYwBZCvmmIO4"
                        rootProps={{ "data-testid": "1" }}
                      />
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">Live updates</h6>
                {/* <p className="card-category d-inline"> today</p> *
                /* <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> *
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      {/* <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Update the Documentation</p>
                          <p className="text-muted">
                            Dwuamish Head, Seattle, WA 8:47 AM
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip636901683"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip636901683"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr> */}
          {/* <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">GDPR Compliance</p>
                          <p className="text-muted">
                            The GDPR is a regulation that requires businesses to
                            protect the personal data and privacy of Europe
                            citizens for transactions that occur within EU
                            member states.
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip457194718"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip457194718"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Solve the issues</p>
                          <p className="text-muted">
                            Fifty percent of all respondents said they would be
                            more likely to shop at a company
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip362404923"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip362404923"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Release v2.0.0</p>
                          <p className="text-muted">
                            Ra Ave SW, Seattle, WA 98116, SUA 11:19 AM
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip818217463"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip818217463"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Export the processed files</p>
                          <p className="text-muted">
                            The report also shows that consumers will not easily
                            forgive a company once a breach exposing their
                            personal data occurs.
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip831835125"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip831835125"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Arival at export process</p>
                          <p className="text-muted">
                            Capitol Hill, Seattle, WA 12:34 AM
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip217595172"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip217595172"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr> *
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
